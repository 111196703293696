<template>
  <div id="app_adminrightclick">
    <v-overlay :value="isloading_unlock" absolute :color="color.BG" dark>
        <v-progress-circular
          indeterminate
          size="64"
          :color="color.theme"></v-progress-circular>
        <br />
        <br />
        <!-- <span :style="colorProgress_biz">&nbsp; &nbsp; loading</span> -->
      </v-overlay>
    <v-bottom-sheet v-model="shower" v-if="imageHeight < 500">
      <v-list dense tile>
        <!-- <v-list-item-group v-model="item" color="primary"> -->
        <v-list-item
          v-for="(item, i) in itemsFilter"
          :key="i"
          @click="goto(item.to)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </v-list-item-group> -->
      </v-list>
    </v-bottom-sheet>
    <v-menu
      :position-x="x"
      :position-y="y"
      offset-y
      close-on-content-click
      close-on-click
      transition="slide-y-transition"
      v-model="shower"
      v-else
    >
      <v-list dense>
        <!-- <v-list-item-group v-model="item" color="primary"> -->
        <v-list-item
          v-for="(item, i) in itemsFilter"
          :key="i"
          @click="goto(item.to)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </v-list-item-group> -->
      </v-list>
    </v-menu>
    <setpermissionfile
      :show="opensetPermissionFile"
      :filedata="currentfile"
      @closesetPermissionFile="
        (opensetPermissionFile = false), $emit('openDrag'), $emit('loaddata')
      "
      @closenoreload="opensetPermissionFile = false"
      @opensetPermissionFile="opensetPermissionFile = true"
      @closeDrag="$emit('closeDrag')"
    ></setpermissionfile>
    <editemail
      :show="openeditemail"
      :email="email"
      :email_all="email_all"
      @close="openeditemail = false"
      @closereload="
        (openeditemail = false), $emit('loademail'), $emit('closeinboxmenu')
      "
      key_email="e"
    ></editemail>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import axios from "axios";

const setpermissionfile = () =>
  import("@/components/optional/dialog-setpermissionfile");
const editemail = () => import("@/components/optional/dialog-editemail");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: [
    "show",
    "AreaX",
    "AreaY",
    "file",
    "parentfolder",
    "parentfoldername",
    "email",
    "email_all",
  ],
  components: {
    setpermissionfile,
    editemail,
  },
  data: function() {
    return {
      isloading_unlock: false,
      openeditemail: false,
      listdowload: [],
      timeout: 60000,
      percentCompleted: 0,
      namedowload: "",
      opensetPermissionFile: false,
      currentfile: {},
      items: [
        // {
        //   text: "download",
        //   icon: "mdi-download",
        //   to: "download",
        //   event: "fn"
        // },
        {
          text: "adminrightclick.setpermission",
          icon: "mdi-cogs",
          to: "setPermissionFile",
          event: "fn",
          type: "",
          email: "",
        },
        // {
        //   text: "adminrightclick.download",
        //   icon: "mdi-download-outline",
        //   to: "downloadpermission",
        //   event: "fn",
        //   type:"",
        //   email:""
        // },
        // {
        //   text: "adminrightclick.movetolibrary",
        //   icon: "mdi-book-open-page-variant",
        //   to: "moveToLibrary",
        //   event: "fn",
        //   type:true,
        //   email:""
        // },
        {
          text: "adminrightclick.movetoedit",
          icon: "mdi-border-color",
          to: "edit",
          event: "fn",
          type: false,
          email: true,
        },
        {
          text: "adminrightclick.movetodelete",
          icon: "delete",
          to: "delete",
          event: "fn",
          type: false,
          email: true,
        },
      ],
      // showMenu: false,
      x: 0,
      y: 0,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          this.showmenus();
          this.$emit("closeDrag");
          console.log("testrightclick", this.file);
          console.log("email", this.email);
          console.log(this.AreaX);
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closecurrentonly");
        }
      },
    },
    itemsFilter() {
      if (this.email) {
        return this.items.filter((menu) => menu.email === true);
      } else {
        if (this.file["file_type"] === "folder") {
          return this.items.filter((menu) => menu.type === "");
        } else {
          return this.items.filter(
            (menu) => menu.type === true || menu.type === ""
          );
        }
      }
    },
  },
  methods: {
    goto(parameter) {
      if (parameter === "download") {
        this.$emit("downloadfile");
        console.log("download");
        this.showMenu = false;
      } else if (parameter === "setPermissionFile") {
        this.opensetPermissionFile = true;
        this.currentfile = this.file;
        this.showMenu = false;
      } else if (parameter === 'fn_unlockfilefolder') {
        this.fn_unlockfilefolder(this.file)
      } else if (parameter === "moveToLibrary") {
      } else if (parameter === "edit") {
        this.openeditemail = true;
      } else if (parameter === "delete") {
        this.$emit("deleteemail", this.email);
        this.showMenu = false;
        console.log("deleteemail", this.email);
      } else if (parameter === "downloadpermission") {
        this.$emit("downloadpermission", this.currentfile);
        console.log("downloadpermission");
        this.showMenu = false;
      }
    },
    showmenus() {
      console.log(this.AreaX, this.AreaY);
      this.showMenu = false;
      this.x = this.AreaX;
      this.y = this.AreaY;
      console.log(this.x, this.y);
      this.$nextTick(() => {
        this.fn_addunlockfile() // เพิ่มมานะ 
        this.showMenu = true;
      });
    },
    closeDialog() {
      this.openinboxdetail = false;
      this.$emit("loaddatafrommainright");
      this.$emit("openDrag");
    },
    fn_addunlockfile () {
      if(this.file.status_lock === 'Y'){
        let findfn_ = this.items.findIndex((item) => item.to === 'fn_unlockfilefolder')
        if(findfn_ !== -1){
          return
        } else {
          this.items.push({
            text: this.file.file_type === 'folder' ? this.$t('adminrightclick.unlockfolder_right') : this.$t('adminrightclick.unlockfile_right'),
            icon: "mdi-lock-open-variant",
            to: "fn_unlockfilefolder",
            event: "fn",
            type: "",
            email: "",
          })
        }
        } else {
          let findfn_ = this.items.findIndex((item) => item.to === 'fn_unlockfilefolder')
          if( findfn_ !== -1) {
              this.items = this.items.slice(0, findfn_)
          }
        }
    },
    async fn_unlockfilefolder (file) {
      this.isloading_unlock = true
      let auth = await gbfGenerate.generateToken();
      let payload = {
        data_id: file.file_id,
        data_type: file.file_type,
        account_admin: this.dataAccountId

      }
      this.axios
      .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/admin/reset/lock_file", 
        payload, { headers: { Authorization: auth.code } })
      .then((response) => {
        if(response.data.status === 'OK'){
          this.isloading_unlock = false
          Toast.fire({
            icon: "success",
            title: this.$t('admin.unlock_complete')
          });
          this.$emit("loaddata")
        } else {
          this.isloading_unlock = false
          Toast.fire({
            icon: "error",
            title: this.$t('admin.unlock_notcomplete')
          });
          this.$emit("unlocknotsuccess")
        }
      })
      .catch((err) => {
        this.isloading_unlock = false
        Toast.fire({
          icon: "error",
          title: this.$t('admin.unlock_notcomplete')
        });
        this.$emit("unlocknotsuccess")
      })
    }


  },
};
</script>
<style>
#app_adminrightclick {
  font-family: "Sarabun", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: white;
}
</style>
